<template>
    <div class="main">
        <div class="home deactivated-page">
            <section class="deactivated">
                <div class="container">
                    <div class="box-success">
                        <h2 class="title-secondary">{{ getMyLanguage("Deactivate", "deactivate.success.title") }}</h2>
                        <p>{{ getMyLanguage("Deactivate", "deactivate.success.msg") }}</p>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
    import getMyContentLanguage from "@/services/contentLanguage.js";
  
    export default {
        data(){
            return{
                flagSelected: "en",
                load: true,
                PageEntity: "ecosystem",
            }
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code

                document.title = this.getMyLanguage("seo","title.account.activate")
                document.getElementsByTagName('meta')["description"].content = ''
            },
            
            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        },

        created () {
            document.title = this.getMyLanguage("seo","title.account.activate");
            document.getElementsByTagName('meta')["description"].content = ''
            document.head.querySelector('meta[name=robots]').content = 'noindex, nofollow'
        },
    }
</script>

<style src="./custom-deactivate.scss" lang="scss" scoped />