const $ = require('jquery')
window.$ = $
    
import getMyContentLanguage from "@/services/contentLanguage.js";

import apiPlatform from '@/config/apiPlatform.js'

export default {
    data(){
        return{
            showContent: false,
            load: false,
            PageEntity: "ecosystem",

            flowStep: {
                load: false,
                stp: "step01",

                statusStep01: false,
                statusStep02: false,
                statusStep03: false,
                statusStep04: false
            },

            flowHeaderStore: {
                modal: false,
                load: false,
                showUpload: false,
                confirm: false
            },

            flowThemeStore: {
                modal: false,
                load: false,
                device: "",
                confirm: false
            },

            flowContact: {
                showContacts: true,
                newContact: false
            },

            flowDock: {
                newDock: false
            },


            // MODAL PHONE VALIDATE
            modalPhoneValidate: false,

            codeSMS: {
                code: ''
            },

            flowSendSMS: {
                load: false,
                smsCodeShow: false,
                btnResendSMS: false,
                success: false,
                countDownResendCode: 0
            },

            errorsFlowSendSMS: {
                flow: false,
                invalidCode: false
            },


            // ERRORS API
            errorsGet: {
                dataInvite: false
            },

            errorsPost: {
                dataProfile: false,
                country_pep: false,
                general: false
            },

            validateEmail: {
                company: {
                    regex: '',
                    status: '',
                    val: ''
                },

                person: {
                    regex: '',
                    status: '',
                    val: ''
                }
            },
            
            validatePhone: {
                company: {
                    regex: '',
                    status: '',
                    val: ''
                },
                
                seller: {
                    regex: '',
                    status: '',
                    val: ''
                },

                contact01: {
                    regex: '',
                    status: '',
                    val: ''
                },

                contact02: {
                    regex: '',
                    status: '',
                    val: ''
                },

                dock: {
                    regex: '',
                    status: '',
                    val: ''
                }
            },

            flow: {
                typeCode: "account_type_person",
            },

            dataCountry: [],
            dataLanguage: [],
            
            flagSelected: "en",
            showFlag: false,
            showFlagCompany: false,

            listLanguagesStatus: false,
            selectedLanguage: {},
            selectedLanguageFlag: false,
            
            listLanguagesCompanyStatus: false,
            selectedLanguageCompany: {},
            selectedLanguageCompanyFlag: false,
            
            listLanguagesSellerStatus: false,
            selectedLanguageSeller: {},
            selectedLanguageSellerFlag: false,
            
            listLanguagesContactStatus: false,
            selectedLanguageContact: {},
            selectedLanguageContactFlag: false,
            
            listLanguagesDockStatus: false,
            selectedLanguageDock: {},
            selectedLanguageDockFlag: false,

            // listCountryStatus: false,
            // selectedCountry: {},
            // selectedCountryFlag: false,
            
            listCountryCompanyStatus: false,
            selectedCountryCompany: {},
            selectedCountryFlagCompany: false,
            
            listCountrySellerStatus: false,
            selectedCountrySeller: {},
            selectedCountryFlagSeller: false,
            
            listCountryContactStatus: false,
            selectedCountryContact: {},
            selectedCountryFlagContact: false,
            
            listCountryDockStatus: false,
            selectedCountryDock: {},
            selectedCountryFlagDock: false,
            
            selectedCountryDDICompany: {
                changeMask: true,
                phoneMask: '?',
                countrySelected: false,
                flag: ''
            },
            
            selectedCountryDDISeller: {
                changeMask: true,
                phoneMask: '?',
                countrySelected: false,
                flag: ''
            },
            
            selectedCountryDDIContact01: {
                changeMask: true,
                phoneMask: '?',
                countrySelected: false,
                flag: ''
            },
            
            selectedCountryDDIContact02: {
                changeMask: true,
                phoneMask: '?',
                countrySelected: false,
                flag: ''
            },
            
            selectedCountryDDIDock: {
                changeMask: true,
                phoneMask: '?',
                countrySelected: false,
                flag: ''
            },

            // selectedCountryDDI: {
            //     changeMask: true,
            //     phoneMask: '?',
            //     countrySelected: false,
            //     flag: ''
            // },

            dropListOpened: {
                languageCompany: false,
                countryCompany: false,
                ddiCompany: false,
                
                languageSeller: false,
                countrySeller: false,
                ddiSeller: false,
            },

            dataProfile: {
                companyProfile: {},
                sellerProfile: {},
                contactProfile: {},
                dockProfile: {},
                
                fullName: "",
                email: "",
                languageId: "",
                countryId: "",
                phoneCountryId: "",
                phoneNumber: "",
                AccountType: "account_type_company",

                // dock: {
                //     countryId: "",
                //     postalCode: "",
                //     address1: "",
                //     address2: "",
                //     city: "",
                //     state: ""
                // }
            },
            

            errorForm: {
                companyProfile: {},
                sellerProfile: {},
                contactProfile01: {},
                contactProfile02: {},
                dockProfile: {},
                // acceptsInvite: {
                //     acceptTerms: false,
                //     acceptNotify: false,
                //     acceptGdpr: false
                // },

                company: false,
                seller: false,
                contact01: false,
                contact02: false,
                dock: false,
                accepts: false
            },

            accept: {
                terms: false,
                notify: false,
                gdpr: false
            },

            resendLoad: false
        }
    }, 

    async mounted(){
        await apiPlatform.get('/api/v1/platform/language/dne_ecosystem')
        .then(response => {
            this.dataLanguage = response.data
        })
        .catch(error => {
            this.errorsGet.dataInvite = error.response.data
        })

        
        setTimeout(() => {
            this.showContent = true
        }, 500);


        const resumeClientCountry = await apiPlatform.get('api/v1/platform/country')
        this.dataCountry = resumeClientCountry.data

        this.showContent = true
    },
    
    methods: {
        languageSelected(){
            this.flagSelected = this.$store.state.SelectLanguage.code

            document.title = this.getMyLanguage("profile","profile-corporate.title");
            document.getElementsByTagName('meta')["description"].content = ''
        },

        getMyLanguage(group, code){
            return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
        },

        goTop(){
            $('html, body').animate({scrollTop : 0},200);
            return false;
        },

        maskedPhone(phoneNumber) {
            if (!phoneNumber) return ''
            const visibleStart = phoneNumber.slice(0, 2)
            const visibleEnd = phoneNumber.slice(-2)

            return `${visibleStart}****${visibleEnd}`
        },


        // NAVIGATION STEPS
        navStep(value){
            if ( value == "stp01" ){
                // verifica se há alterações que serão perdidas e gera alerta ....

                this.goTop()
                this.flowStep.load = true

                setTimeout(() => {
                    this.flowStep.load = false
                    this.flowStep.stp = "step01"
                }, 1500);
            }
            
            if ( value == "stp02" ){
                // verifica se há alterações que serão perdidas e gera alerta ....

                this.goTop()
                this.flowStep.load = true

                setTimeout(() => {
                    this.flowStep.load = false
                    this.flowStep.stp = "step02"
                }, 1500);
            }
            
            if ( value == "stp03" ){
                // verifica se há alterações que serão perdidas e gera alerta ....

                this.goTop()
                this.flowStep.load = true

                setTimeout(() => {
                    this.flowStep.load = false
                    this.flowStep.stp = "step03"
                }, 1500);
            }
        },


        
        // PHONE VALIDATE
        async getCodeSend(){
            this.flowSendSMS.smsCodeShow = false
            this.flowSendSMS.load = true
            this.errors.invalidCode = false
            this.codeSMS.code = ''

            await apiPlatform.get('/api/v1/platform/security/phone/validate')
            .then(response => {
                console.log(response)
                console.clear()

                setTimeout(() => {
                    this.flowSendSMS.smsCodeShow = true
                    this.flowSendSMS.load = false
                    this.flowSendSMS.btnResendSMS = false

                    this.flowSendSMS.countDownResendCode = 15
                    this.countDownFlowSend()
                }, 300);

                setTimeout(() => {
                    document.getElementById('smsCodeForm').focus()
                }, 400);
            })
            .catch(error => {
                setTimeout(() => {
                    this.errors.flow = error.response.status
                }, 500);

                setTimeout(() => {
                    this.errors.flow = false
                    this.flowSendSMS.load = false
                }, 5000);
            })
        },

        countDownFlowSend() {
            if(this.flowSendSMS.countDownResendCode > 0) {
                setTimeout(() => {
                    this.flowSendSMS.countDownResendCode -= 1
                    this.countDownFlowSend()
                }, 1000)
            } else{
                this.flowSendSMS.btnResendSMS = true
            }
        },

        async validateCodeSMS(){
            this.flowSendSMS.load = true
            
            await apiPlatform.post('/api/v1/platform/security/phone/validate', this.codeSMS)
            .then(response => {
                console.log(response)
                console.clear()

                setTimeout(() => {
                    this.flowSendSMS.success = true
                    this.flowSendSMS.load = false
                    this.errors.invalidCode = false

                    this.$store.commit('validatePhoneNumber', true)
                }, 1000);
            })
            .catch(error => {
                if ( error.response.status === 400 ){
                    if ( error.response.data === "phone_number_invalid_code" ){
                        this.errors.invalidCode = true
                        this.flowSendSMS.load = false
                    } else{
                        this.errors.flow = error.response.status
                    }
                }
                
                this.flowSendSMS.load = false

                setTimeout(() => {
                    this.errors.flow = false
                }, 5000);
            })
        },



        // VALIDATE REGEX
        regexEmail(emailCheck){
            const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            if ( emailCheck === "company"){
                if (regex.test(this.validateEmail.company.val)) {
                    this.validateEmail.company.regex = true
                } else{
                    this.validateEmail.company.regex = false
                }
            } else if ( emailCheck === "person"){
                if (regex.test(this.validateEmail.person.val)) {
                    this.validateEmail.person.regex = true
                } else{
                    this.validateEmail.person.regex = false
                }
            }
        },

        regexPhone(phoneCheck){
            const regex = /^\+[1-9]\d{8,14}$/;

            if ( phoneCheck === "company"){
                let phoneE164Company = this.selectedCountryDDICompany.ddi + this.validatePhone.company.val
                phoneE164Company = "+" + phoneE164Company.replace(/[^0-9]/g,'')
                
                if (regex.test(phoneE164Company)) {
                    this.validatePhone.company.regex = true
                } else{
                    this.validatePhone.company.regex = false
                }

            } else if ( phoneCheck === "seller"){
                let phoneE164Seller = this.selectedCountryDDISeller.ddi + this.validatePhone.seller.val
                phoneE164Seller = "+" + phoneE164Seller.replace(/[^0-9]/g,'')
                
                if (regex.test(phoneE164Seller)) {
                    this.validatePhone.seller.regex = true
                } else{
                    this.validatePhone.seller.regex = false
                }

            } else if ( phoneCheck === "contact01"){
                let phoneE164Contact01 = this.selectedCountryDDIContact01.ddi + this.validatePhone.contact01.val
                phoneE164Contact01 = "+" + phoneE164Contact01.replace(/[^0-9]/g,'')
                
                if (regex.test(phoneE164Contact01)) {
                    this.validatePhone.contact01.regex = true
                } else{
                    this.validatePhone.contact01.regex = false
                }

            } else if ( phoneCheck === "contact02"){
                let phoneE164Contact02 = this.selectedCountryDDIContact02.ddi + this.validatePhone.contact02.val
                phoneE164Contact02 = "+" + phoneE164Contact02.replace(/[^0-9]/g,'')
                
                if (regex.test(phoneE164Contact02)) {
                    this.validatePhone.contact02.regex = true
                } else{
                    this.validatePhone.contact02.regex = false
                }

            } else if ( phoneCheck === "dock"){
                let phoneE164Dock = this.selectedCountryDDIDock.ddi + this.validatePhone.dock.val
                phoneE164Dock = "+" + phoneE164Dock.replace(/[^0-9]/g,'')
                
                if (regex.test(phoneE164Dock)) {
                    this.validatePhone.dock.regex = true
                } else{
                    this.validatePhone.dock.regex = false
                }
            }
        },

        checkEmail(emailCheck){
            if ( emailCheck === "company" ){
                if ( this.validateEmail.company.val === this.dataProfile.companyProfile.email ){
                    this.validateEmail.company.status = true
                } else{
                    this.validateEmail.company.status = false
                }
            } else if ( emailCheck === "person" ){
                if ( this.validateEmail.person.val === this.dataProfile.email ){
                    this.validateEmail.person.status = true
                } else{
                    this.validateEmail.person.status = false
                }
            }
        },



        // LIST COUNTRY
        dropListCountry(status,place){
            if ( place == "company" ){
                setTimeout(() => {
                    this.listCountryCompanyStatus = status
                }, 250)
                
                if ( status ){
                    setTimeout(() => {
                        this.dropListOpened.countryCompany = true
                        document.getElementById('residenceCountryCompany').focus()
                    }, 10);
                } else {
                    this.dropListOpened.countryCompany = false
                }
            }

            if ( place == "seller" ){
                setTimeout(() => {
                    this.listCountrySellerStatus = status
                }, 250)
                
                if ( status ){
                    setTimeout(() => {
                        this.dropListOpened.countrySeller = true
                        document.getElementById('residenceCountrySeller').focus()
                    }, 10);
                } else {
                    this.dropListOpened.countrySeller = false
                }
            }

            if ( place == "contact" ){
                setTimeout(() => {
                    this.listCountryContactStatus = status
                }, 250)
                
                if ( status ){
                    setTimeout(() => {
                        this.dropListOpened.countryContact = true
                        document.getElementById('residenceCountryContact').focus()
                    }, 10);
                } else {
                    this.dropListOpened.countryContact = false
                }
            }

            if ( place == "dock" ){
                setTimeout(() => {
                    this.listCountryDockStatus = status
                }, 250)
                
                if ( status ){
                    setTimeout(() => {
                        this.dropListOpened.countryDock = true
                        document.getElementById('residenceCountryDock').focus()
                    }, 10);
                } else {
                    this.dropListOpened.countryDock = false
                }
            }
        },

        filterListCountry(place) {
            var input, filter, ul, li, a, i, txtValue;
            input = document.getElementById(place + "ResidenceCountry");
            filter = input.value.toUpperCase();
            ul = document.getElementById("drop-list-" + place);
            li = ul.getElementsByTagName("li");

            for (i = 0; i < li.length; i++) {
                a = li[i].getElementsByTagName("a")[0];
                txtValue = a.textContent || a.innerText;
                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                        li[i].style.display = "";
                } else {
                        li[i].style.display = "none";
                }
            }
        },


        filterListCountryCompany() {
            var input, filter, ul, li, a, i, txtValue;
            input = document.getElementById("residenceCompanyCountry");
            filter = input.value.toUpperCase();
            ul = document.getElementById("drop-list");
            li = ul.getElementsByTagName("li");

            for (i = 0; i < li.length; i++) {
                a = li[i].getElementsByTagName("a")[0];
                txtValue = a.textContent || a.innerText;
                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                        li[i].style.display = "";
                } else {
                        li[i].style.display = "none";
                }
            }
        },

        filterListCountrySeller() {
            var input, filter, ul, li, a, i, txtValue;
            input = document.getElementById("residenceSellerCountry");
            filter = input.value.toUpperCase();
            ul = document.getElementById("drop-list");
            li = ul.getElementsByTagName("li");

            for (i = 0; i < li.length; i++) {
                a = li[i].getElementsByTagName("a")[0];
                txtValue = a.textContent || a.innerText;
                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                        li[i].style.display = "";
                } else {
                        li[i].style.display = "none";
                }
            }
        },

        filterListCountryContact() {
            var input, filter, ul, li, a, i, txtValue;
            input = document.getElementById("residenceContactCountry");
            filter = input.value.toUpperCase();
            ul = document.getElementById("drop-list");
            li = ul.getElementsByTagName("li");

            for (i = 0; i < li.length; i++) {
                a = li[i].getElementsByTagName("a")[0];
                txtValue = a.textContent || a.innerText;
                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                        li[i].style.display = "";
                } else {
                        li[i].style.display = "none";
                }
            }
        },

        filterListCountryDock() {
            var input, filter, ul, li, a, i, txtValue;
            input = document.getElementById("residenceDockCountry");
            filter = input.value.toUpperCase();
            ul = document.getElementById("drop-list");
            li = ul.getElementsByTagName("li");

            for (i = 0; i < li.length; i++) {
                a = li[i].getElementsByTagName("a")[0];
                txtValue = a.textContent || a.innerText;
                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                        li[i].style.display = "";
                } else {
                        li[i].style.display = "none";
                }
            }
        },


        // MASK PHONE
        changeMask(maskCheck){
            if ( maskCheck == "company" ){
                this.selectedCountryDDICompany.changeMask = false

                setTimeout(() => {
                    this.selectedCountryDDICompany.changeMask = true
                }, 1000);

                setTimeout(() => {
                    document.getElementById('phoneNumberCompany').focus()
                }, 1050);
            }
            
            if ( maskCheck == "seller" ){
                this.selectedCountryDDISeller.changeMask = false

                setTimeout(() => {
                    this.selectedCountryDDISeller.changeMask = true
                }, 1000);

                setTimeout(() => {
                    document.getElementById('phoneNumberSeller').focus()
                }, 1050);
            }
            
            if ( maskCheck == "contact" ){
                this.selectedCountryDDIContact01.changeMask = false
                this.selectedCountryDDIContact02.changeMask = false

                setTimeout(() => {
                    this.selectedCountryDDIContact01.changeMask = true
                    this.selectedCountryDDIContact02.changeMask = true
                }, 1000);

                setTimeout(() => {
                    document.getElementById('phoneNumberContact01').focus()
                }, 1050);
            }

            if ( maskCheck == "dock" ){
                this.selectedCountryDDIDock.changeMask = false

                setTimeout(() => {
                    this.selectedCountryDDIDock.changeMask = true
                }, 1000);

                setTimeout(() => {
                    document.getElementById('phoneNumberDock').focus()
                }, 1050);
            }
        },
        


        // LANGUAGES
        dropListLanguages(status,place){
            if ( place == "company" ){
                setTimeout(() => {
                    this.listLanguagesCompanyStatus = status
                }, 250)
    
                if ( status ){
                    setTimeout(() => {
                        this.dropListOpened.languageCompany = true
                        document.getElementById('languageIDcompany').focus()
                    }, 10);
                } else {
                    this.dropListOpened.languageCompany = false
                }
            }
            
            if ( place == "seller" ){
                setTimeout(() => {
                    this.listLanguagesSellerStatus = status
                }, 250)
    
                if ( status ){
                    setTimeout(() => {
                        this.dropListOpened.languageSeller = true
                        document.getElementById('languageIDseller').focus()
                    }, 10);
                } else {
                    this.dropListOpened.languageSeller = false
                }
            }
        },

        filterListLanguage(place) {
            var input, filter, ul, li, a, i, txtValue;
            input = document.getElementById("languageID" + place);
            filter = input.value.toUpperCase();
            ul = document.getElementById("drop-list-" + place);
            li = ul.getElementsByTagName("li");
            
            for (i = 0; i < li.length; i++) {
                a = li[i].getElementsByTagName("a")[0];
                txtValue = a.textContent || a.innerText;
                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                        li[i].style.display = "";
                } else {
                        li[i].style.display = "none";
                }
            }
        },


        changeFlagLanguage(value){
            this.showFlag = false
            this.dataProfile.languageId = value

            this.showFlag = true
        },



        // HEADER STORE
        changeHeaderStore(){
            this.flowHeaderStore.load = true

            setTimeout(() => {
                this.flowHeaderStore.load = false
                this.flowHeaderStore.modal = false
                this.flowHeaderStore.confirm = false

                this.$toast.success("Topo da loja atualizado")
            }, 1500);
            
            // setTimeout(this.$toast.clear, 1000)


        },



        // THEMES
        previewTheme(){
            this.usedDevice()
            this.flowThemeStore.modal = true
        },

        usedDevice(){
            const largura = window.innerWidth
            if (largura <= 576) {
                this.flowThemeStore.device = 'mobile'
            } else if (largura <= 992) {
                this.flowThemeStore.device = 'tablet'
            } else {
                this.flowThemeStore.device = 'desktop'
            }
        },

        changeThemeStore(){
            this.flowThemeStore.load = true

            setTimeout(() => {
                this.flowThemeStore.load = false
                this.flowThemeStore.modal = false
                this.flowThemeStore.devide = ""
                this.flowThemeStore.confirm = false

                this.$toast.success("Tema atualizado")
            }, 1500);
            
            // setTimeout(this.$toast.clear, 1000)
        },

        

        // ADDRESS CONTACT
        changeContact(value){
            if ( value == 'new' ){
                this.flowContact.newContact = true
            } else {
                this.flowContact.newContact = false
            }
        },



        // ADDRESS DOCK
        changeDock(value){
            console.log(value)
            
            // value deve conter o array do endereço
            // atualizar data relativo a doca com o value

            if ( value == "new" ){
                this.flowDock.newDock = true
            } else {
                this.flowDock.newDock = false
            }
        },










         // VALIDATE FORM
         validateForm(value){
            if ( value == "stp01" ){
                // validações ....

                // no then, atualizar data de contacto (step 03) - changeContact()
                // no then, atualizar data da doca (step 03) - changeDock()

                this.goTop()
                this.flowStep.load = true

                setTimeout(() => {
                    this.flowStep.load = false
                    this.flowStep.stp = "step02"
                    this.flowStep.statusStep01 = true
                }, 1500);
            }
            
            if ( value == "stp02" ){
                // validações ....

                this.goTop()
                this.flowStep.load = true

                setTimeout(() => {
                    this.flowStep.load = false
                    this.flowStep.stp = "step03"
                    this.flowStep.statusStep02 = true
                }, 1500);
            }
        },


        validationFormInvite(){

            // VALIDATION COMPLIANCE
            if ( this.dataProfile.AccountType === "company_invite" ){

                // COMPANY NAME
                if ( this.dataProfile.companyProfile.fullName != "" ){
                    var companyName = this.dataProfile.companyProfile.fullName
                    companyName = companyName.trim().split(' ').length
                    
                    if ( companyName < 2 ){
                        this.errorForm.companyProfile.fullName = true
                    } else {
                        this.errorForm.companyProfile.fullName = false
                    }
                } else {
                    this.errorForm.companyProfile.fullName = true
                }


                // COMPANY EMAIL
                if ( this.dataProfile.companyProfile.email === "" ){
                    this.errorForm.companyProfile.email = true
                } else {
                    this.errorForm.companyProfile.email = false
                }


                // COMPANY LANGUAGE
                if ( this.dataProfile.companyProfile.languageId === "" ){
                    this.errorForm.companyProfile.languageId = true
                } else {
                    this.errorForm.companyProfile.languageId = false
                }


                // COMPANY COUNTRY
                if ( this.dataProfile.companyProfile.countryId === "" ){
                    this.errorForm.companyProfile.countryId = true
                } else {
                    this.errorForm.companyProfile.countryId = false
                }


                // COMPANY PHONE
                if ( this.validatePhone.company.val === "" ){
                    this.errorForm.companyProfile.phoneNumber = true
                } else if ( this.validatePhone.company.val.length < this.selectedCountryDDICompany.phoneMask.length ) {
                    this.errorForm.companyProfile.phoneNumber = true
                } else {
                    this.errorForm.companyProfile.phoneNumber = false
                }

                // COMPANY TAXNUMBER
                if ( this.dataProfile.companyProfile.taxNumber ==="" ){
                    this.errorForm.companyProfile.taxNumber = true
                } else {
                    this.errorForm.companyProfile.taxNumber = false
                }


                // CKECK VALIDATION COMPLIANCE
                if ( this.errorForm.companyProfile.fullName || this.errorForm.companyProfile.email || this.errorForm.companyProfile.languageId || this.errorForm.companyProfile.countryId || this.errorForm.companyProfile.phoneNumber || this.errorForm.companyProfile.taxNumber ){
                    window.location.href = "#!company"
                    this.errorForm.company = true
                } else {
                    this.errorForm.company = false
                }
            }



            
            // PERSON NAME
            if ( this.dataProfile.fullName != "" ){
                var personName = this.dataProfile.fullName
                personName = personName.trim().split(' ').length
                
                if ( personName < 2 ){
                    this.errorForm.sellerProfile.fullName = true
                } else {
                    this.errorForm.sellerProfile.fullName = false
                }
            } else {
                this.errorForm.sellerProfile.fullName = true
            }


            // EMAIL
            if ( this.dataProfile.email === "" ){
                this.errorForm.sellerProfile.email = true
            } else {
                this.errorForm.sellerProfile.email = false
            }


            // LANGUAGE
            if ( this.dataProfile.languageId === "" ){
                this.errorForm.sellerProfile.languageId = true
            } else {
                this.errorForm.sellerProfile.languageId = false
            }


            // COUNTRY
            if ( this.dataProfile.countryId === "" ){
                this.errorForm.sellerProfile.countryId = true
            } else {
                this.errorForm.sellerProfile.countryId = false
            }


            // PHONE
            if ( this.validatePhone.person.val === "" ){
                this.errorForm.sellerProfile.phoneNumber = true
            } else if ( this.validatePhone.person.val.length < this.selectedCountryDDI.phoneMask.length ) {
                this.errorForm.sellerProfile.phoneNumber = true
            } else {
                this.errorForm.sellerProfile.phoneNumber = false
            }


            // ACCEPTS
            if ( !this.accept.terms ){
                this.errorForm.acceptsInvite.acceptTerms = true
            } else{
                this.errorForm.acceptsInvite.acceptTerms = false
            }
            
            if ( !this.accept.notify ){
                this.errorForm.acceptsInvite.acceptNotify = true
            } else{
                this.errorForm.acceptsInvite.acceptNotify = false
            }
            
            if ( !this.accept.gdpr ){
                this.errorForm.acceptsInvite.acceptGdpr = true
            } else{
                this.errorForm.acceptsInvite.acceptGdpr = false
            }

            if ( this.errorForm.acceptsInvite.acceptTerms || this.errorForm.acceptsInvite.acceptNotify || this.errorForm.acceptsInvite.acceptGdpr ){
                this.errorForm.accepts = true
            } else {
                this.errorForm.accepts = false
            }


            // CKECK VALIDATION COMPLIANCE
            if ( !this.errorForm.company && (this.errorForm.sellerProfile.fullName || this.errorForm.sellerProfile.email || this.errorForm.sellerProfile.languageId || this.errorForm.sellerProfile.countryId || this.errorForm.sellerProfile.phoneNumber) ){
                window.location.href = "#!person"
                this.errorForm.seller = true
            } else {
                this.errorForm.seller = false
            }


            // LAST CKECK
            if ( !this.errorForm.company && !this.errorForm.accepts && !this.errorForm.seller ){
                this.saveStep()
            }
        },


        
        // SAVE STEP
        saveStep(){
            this.load = true
            const register = this.dataProfile;

            let dataPhoneCompany = this.validatePhone.company.val
            let dataPhonePerson = this.validatePhone.person.val

            this.dataProfile.companyProfile.phoneNumber = dataPhoneCompany.replace(/[^0-9]/g,'')
            this.dataProfile.phoneNumber = dataPhonePerson.replace(/[^0-9]/g,'')
            

            apiPlatform.post("/api/v1/platform/account/register/", register)
            .then(response => {
                this.registerId = response.data.id;
                const languageRedirect = String(window.location.pathname).split("/")[1];
                window.location.href = `/${languageRedirect}/account/confirm-email-invite/${this.$route.params.registerId}`
            })
            .catch(error => {
                console.log(error.response.data)

                if ( error.response.data === "account_activate_email_already_exists" || error.response.data === "account_activate_phone_already_exists" ) {
                    this.errorsPost.dataProfile = error.response.data
                } else if ( error.response.data === "not_allowed_restricted_country_pep" ) {
                    this.errorsPost.country_pep = true
                } else{
                    this.errorsPost.general = error.response.status

                    setTimeout(() => {
                        this.errorsPost.general = false
                    }, 4000);
                }

                console.log(this.errorsPost)

                setTimeout(() => {
                    this.load = false
                }, 500);
            })
        },
    }
}