<template>
    <div class="main">
        <div class="home deactivate-page">
            <div id="dne"></div>
            <Header :dataSource="PageEntity" />

            <section class="deactivate">
                <div class="load load-200" v-if="load"></div>

                <div class="container" v-if="!load && !errorsPost.dataDeactivate">
                    <div class="cont-deactivate">
                        <h2 class="title-secondary">
                            {{ getMyLanguage("Deactivate", "deactivate.title") }}
                        </h2>

                        <div class="alert">
                            <img src="~@/assets/images/icons/alert-white.svg">
                            <p><strong>{{ getMyLanguage("Deactivate", "deactivate.alert") }}</strong></p>
                        </div>

                        <div class="reason">
                            <h3>{{ getMyLanguage("Deactivate", "deactivate.reason.title") }}</h3>

                            <div class="options">
                                <div class="form-group-radio" v-for="(item, index) in dataReasons" :key="index">
                                    <input type="radio" class="form-radio" 
                                        :id="item.Id" 
                                        :value="item.Id"
                                        v-model="dataDeactivate">
                                        
                                    <label class="form-radio-label" :for="item.Id">
                                        <span>{{ getMyLanguage("Deactivate", "deactivate.reason." + item.Extra) }}</span>
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div class="action">
                            <h3>{{ getMyLanguage("Deactivate","deactivate.action.title") }}</h3>

                            <div class="bts">
                                <div class="btn-cancel"
                                    @click="cancelDeactivate()">
                                    
                                    {{ getMyLanguage("Deactivate","deactivate.action.btn-no") }}
                                </div>

                                <div class="btn-primary btn-disabled"
                                    v-if="!dataDeactivate">
                                    
                                    {{ getMyLanguage("Deactivate","deactivate.action.btn-yes") }}
                                </div>

                                <div class="btn-primary"
                                    v-else
                                    @click="deactivateAccount()">
                                    
                                    {{ getMyLanguage("Deactivate","deactivate.action.btn-yes") }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- ERROR -->
                <div class="container" v-if="!load && errorsPost.dataDeactivate != false">
                    <div class="box-error">
                        <h3 class="title-secondary">{{ getMyLanguage("box-error", "title-error") }}</h3>
                        <p>{{ getMyLanguage("box-error", "general-error") }}</p>
                        <div class="cod-error">
                            Cód.: {{errorsPost.dataDeactivate}}
                        </div>
                    </div>
                </div>
            </section>
        </div>


        <Footer />
        <CookieConsent />
    </div>
</template>

<script>
    import Header from '@/components/Header.vue';
    import Footer from '@/components/Footer.vue';
    import CookieConsent from '@/components/CookieConsent.vue';

    import getMyContentLanguage from "@/services/contentLanguage.js";

    import apiPlatform from '@/config/apiPlatform.js'
  
    export default {
        components: {
            Header,
            Footer,
            CookieConsent
        },

        data(){
            return{
                flagSelected: "en",
                load: true,
                PageEntity: "ecosystem",

                hasToken: "",
                token: "",

                errorsPost: {
                    dataDeactivate: false,
                },

                dataReasons: [],

                dataDeactivate: ""
            }
        },
        
        async mounted(){
            this.checkToken()
        },
        
        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code

                document.title = this.getMyLanguage("seo","title.account.activate");
                document.getElementsByTagName('meta')["description"].content = ''
            },
            
            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            },

            checkToken(){
                this.hasToken = localStorage.getItem("accessToken")

                if ( this.hasToken ){
                    const base64Url = localStorage.getItem("accessToken").split('.')[1]
                    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
                    const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
                        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
                    }).join(''));
    
                    this.token = JSON.parse(jsonPayload)
    
                    // check validate
                    const currentTimestamp = Math.floor(Date.now() / 1000);
                    
                    if (this.token.exp && this.token.exp > currentTimestamp) {
                        // Token is valid
                        this.loadReasons()
                    } else {
                        // Token is invalid
                        window.location.href = `/${this.flagSelected}/account/login?destiny=account/deactivate`
                    }
                } else {
                    // Token is invalid
                    window.location.href = `/${this.flagSelected}/account/login?destiny=account/deactivate`
                }
            },

            loadReasons(){
                apiPlatform.get("/api/v1/public/attributes/codes/account_deactivate_reason")
                .then(response => {
                    this.dataReasons = response.data
                    console.log(this.dataReasons)

                    setTimeout(() => {
                        this.load = false
                    }, 1000);

                })
                .catch(error => {
                    console.error(error)

                    setTimeout(() => {
                        this.load = false
                    }, 1000);
                    
                })
            },

            async deactivateAccount(){
                this.load = true

                await apiPlatform.post("/api/v2/platform/account/deactivate", {"account_deactivate_reason_id": this.dataDeactivate})
                .then(response => {
                    localStorage.removeItem("accessToken")

                    console.log(response)

                    window.location.href = `/${this.flagSelected}/account/deactivated`

                })
                .catch(error => {
                    console.error(error)

                    this.errorsPost.dataDeactivate = JSON.stringify(error.response.status)

                    setTimeout(() => {
                        this.load = false
                    }, 500);
                })
            },

            cancelDeactivate(){
                window.location.href = `/${this.flagSelected}`
            }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        },

        created () {
            document.title = this.getMyLanguage("seo","title.account.activate");
            document.getElementsByTagName('meta')["description"].content = ''
            document.head.querySelector('meta[name=robots]').content = 'noindex, nofollow'
        },
    }
</script>

<style src="./custom-deactivate.scss" lang="scss" scoped />