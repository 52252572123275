<template>
    <div class="main">
        <div class="account">
            <div id="dne"></div>
            <Header :dataSource="PageEntity" />

            <section class="profile">
                <div class="load load-white load-200" v-if="!showContent"></div>
                
                <!-- ERROR RESTRICTED -->
                <!-- <div class="container" v-if="showContent && errorsPost.country_pep">
                    <div class="box-error">
                        <h3 class="title-secondary">{{ getMyLanguage("box-error", "title-error") }}</h3>
                        <p v-if="dataProfile.pep" v-html='getMyLanguage("box-error","not_allowed_restricted_country_pep-pep")'></p>
                        <p v-if="!dataProfile.pep" v-html='getMyLanguage("box-error","not_allowed_restricted_country_pep-country")'></p>
                    </div>
                </div> -->

                
                <!-- BOX REGISTRATION -->
                <div class="container" v-if='showContent && !errorsPost.dataProfile && dataProfile.AccountType === "account_type_company"'>
                    <!-- <h2 class="title-secondary">Perfil</h2> -->

                    <!-- STEPS -->
                    <div class="steps">
                        <div class="cont" :class="flowStep.stp">
                            <div class="step step-01" :class="{active: flowStep.stp == 'step01',validated: flowStep.statusStep01}" @click='navStep("stp01")'>
                                <span><i>1</i></span>
                                <small>Informações<br>da empresa</small>
                            </div>
                            <div class="step step-02" :class="{active: flowStep.stp == 'step02',validated: flowStep.statusStep02}" @click='navStep("stp02")'>
                                <span><i>2</i></span>
                                <small>Informações<br>do vendedor</small>
                            </div>
                            <div class="step step-03" :class="{active: flowStep.stp == 'step03',validated: flowStep.statusStep03}" @click='navStep("stp03")'>
                                <span><i>3</i></span>
                                <small>Sua loja</small>
                            </div>
                            <div class="step step-04" :class="{active: flowStep.stp == 'step04',validated: flowStep.statusStep04}" @click='navStep("stp04")'>
                                <span><i>4</i></span>
                                <small>Verificações</small>
                            </div>
                        </div>
                    </div>


                    <div class="box-profile">

                        <div class="load" v-if="flowStep.load"></div>

                        <!-- STEP 01 -->
                        <div class="cont-step-01" v-if='flowStep.stp == "step01" && !flowStep.load'>
                            
                            <h2 class="title-secondary">Informações da empresa</h2>

                            <div class="box-registration">
                                <div class="row formBox">                                        

                                    <!-- COMPANY NAME -->
                                    <div class="col-12 col-lg-6">
                                        
                                        <label>
                                            <span>{{getMyLanguage("purchase-token", "purchase-token.content.box-register.placeholder.companyName")}}</span>
                                            <input v-model="dataProfile.companyProfile.fullName" type="text" class="form" :class='{error: errorForm.companyProfile.fullName}' @keydown="errorForm.companyProfile.fullName = false" maxlength="80" autocomplete="off" >
                                        
                                            <p class="msg-validate-form msg-error anime" v-if='errorForm.companyProfile.fullName'>{{getMyLanguage("errors","errors.nameError")}}</p>
                                        </label>
                                    </div>
                                    
                                    <!-- TAX NUMBER -->
                                    <div class="col-12 col-lg-6">
                                        <label>
                                            <span>{{getMyLanguage("purchase-token","purchase-token.content.box-register.placeholder.companyTaxNumber")}}</span>
                                            <input v-model="dataProfile.companyProfile.taxNumber" type="text" class="form" :class="{error: errorForm.companyProfile.taxNumber}" @keydown="errorForm.companyProfile.taxNumber = false" maxlength="80" autocomplete="off" >
                                            <p class="msg-validate-form msg-error anime" v-if='errorForm.companyProfile.taxNumber'>{{getMyLanguage("errors","errors.taxNumber")}}</p>
                                        </label>
                                    </div>

                                    <!-- TYPE OF COMPANY -->
                                    <div class="col-12 col-lg-6">
                                        <label>
                                            <span>Tipo da empresa</span>
                                            <select class="form">
                                                <option value="">AAA</option>
                                                <option value="">BBB</option>
                                                <option value="">CCC</option>
                                                <option value="">DDD</option>
                                                <option value="">EEE</option>
                                            </select>
                                        </label>
                                    </div>

                                    <div class="col-12 col-lg-6">
                                        <label>
                                            <span>Ramo de atividade</span>
                                            <select class="form">
                                                <option value="">111</option>
                                                <option value="">222</option>
                                                <option value="">333</option>
                                                <option value="">444</option>
                                                <option value="">555</option>
                                            </select>
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div class="box-registration">
                                <div class="row formBox">
                                    
                                    <!-- EMAIL COMPANY -->
                                    <div class="col-12 col-lg-6">
                                        <div class="form-validate">
                                            <label class="formYourEmail">
                                                <span>{{getMyLanguage("purchase-token","purchase-token.content.box-register.placeholder.companyEmail")}}</span>
                                                <div class="email Active">email@email.com</div>
                                                <!-- <small>* O registo está incompleto</small> -->
                                            </label>
                                        </div>
                                    </div>

                                    <!-- PHONE COMPANY -->
                                    <div class="col-12 col-lg-6">
                                        <div class="form-validate">
                                            <label>
                                                <span>{{getMyLanguage("purchase-token", "purchase-token.content.box-register.placeholder.companyPhone")}}</span>
                                                <!-- <img :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${selectedLanguageCompanyFlag}/flags`" class="flag" v-if="selectedLanguageCompanyFlag != false"> -->
                                                <img src="https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/ef8d7ffc-7765-4aa5-75be-3c8758b7ff00/flags" class="flag">
                                                <div class="phone Pending">351315135151</div>
                                            </label>

                                            <div class="validate-phone" @click="modalPhoneValidate = true">{{getMyLanguage("profile","profile.account.lbl.phone.validate.btn")}}</div>
                                        </div>
                                    </div>

                                    <!-- CURRENCY COMPANY -->
                                    <div class="col-12 col-lg-6">
                                        <label>
                                            <span>{{ getMyLanguage("client-space", "client-space.account.lbl.currency") }}</span>
                                            <select class="form">
                                                <option value="">Euro</option>
                                                <option value="">Dollar</option>
                                                <option value="">Real Brasileiro</option>
                                            </select>
                                        </label>
                                    </div>

                                    <!-- LANGUAGE COMPANY -->
                                    <div class="col-12 col-lg-6">
                                        <div class="autocomplete has-label no-icon formLanguage" :class="{opened: dropListOpened.languageCompany}">
                                            <div class="openAutocomplete" v-if="!listLanguagesCompanyStatus" @click="dropListLanguages(true,'company')"></div>
                                            <div class="closeAutocomplete closeDropListCountry" v-if="listLanguagesCompanyStatus" @click="dropListLanguages(false,'company')"></div>

                                            <label>
                                                <span>{{getMyLanguage("purchase-token", "purchase-token.content.box-register.placeholder.language")}}</span>
                                                <img :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${selectedLanguageCompanyFlag}/flags`" class="flag-selected" v-if="selectedLanguageCompanyFlag != false">
                                                <input type="text" id="languageIDcompany" class="form" :class="{selected: selectedLanguageCompanyFlag != false, error: errorForm.companyProfile.languageId}" @focus="dropListLanguages(true,'company')" @keyup="filterListLanguage('company')" @blur="dropListLanguages(false,'company')" @keydown.esc="dropListLanguages(false,'company')" :value="selectedLanguageCompany.name" :title='getMyLanguage("purchase-token", "purchase-token.content.box-register.placeholder.language")' autocomplete="off">
                                                <p class="msg-validate-form msg-error anime" v-if='errorForm.companyProfile.languageId'>{{getMyLanguage("errors","errors.language")}}</p>
                                            </label>

                                            <ul id="drop-list-company" v-if="listLanguagesCompanyStatus">
                                                <li v-for="(itemList,index) in dataLanguage" :key="index" @click="dataProfile.companyProfile.languageId = itemList.id, selectedLanguageCompany.name = itemList.name, selectedLanguageCompanyFlag = itemList.flag, dropListLanguages(false,'company'), errorForm.companyProfile.languageId = false">
                                                    <img :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${itemList.flag}/flags`">
                                                    <a>{{itemList.name}}</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="box-registration">
                                <div class="row formBox">

                                    <!-- COUNTRY COMPANY -->
                                    <div class="col-12 col-lg-6">
                                        <div class="autocomplete has-label no-icon formResidenceCountry" :class="{opened: dropListOpened.countryCompany}">
                                            <div class="openAutocomplete" v-if="!listCountryCompanyStatus" @click="dropListCountry(true,'company')"></div>
                                            <div class="closeAutocomplete closeDropListCountry" v-if="listCountryCompanyStatus" @click="dropListCountry(false,'company')"></div>

                                            <label>
                                                <img :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${selectedCountryFlagCompany}/flags`" class="flag-selected" v-if="selectedCountryFlagCompany != false">
                                                <span>{{getMyLanguage("purchase-token", "purchase-token.content.box-register.placeholder.companyCountry")}}</span>
                                                <input type="text" id="residenceCountryCompany" class="form" :class="{selected: selectedCountryFlagCompany != false, error: errorForm.companyProfile.countryId}" @focus="dropListCountry(true,'company')" @blur="dropListCountry(false,'company')" @keydown.esc="dropListCountry(false,'company')" @keyup="filterListCountryCompany" :value="selectedCountryCompany.name" autocomplete="off">
                                                <p class="msg-validate-form msg-error anime" v-if='errorForm.companyProfile.countryId'>{{getMyLanguage("errors","errors.residenceError")}}</p>
                                            </label>

                                            <ul id="drop-list" v-if="listCountryCompanyStatus">
                                                <li v-for="(itemListCompany,index) in dataCountry" :key="index" @click="dataProfile.companyProfile.countryId = itemListCompany.id, dataProfile.companyProfile.phoneCountryId = itemListCompany.id, changeMask('company'), selectedCountryFlagCompany = itemListCompany.flag, selectedCountryDDICompany.countrySelected = true, selectedCountryDDICompany.active = 'active', selectedCountryDDICompany.phoneMask = itemListCompany.phoneMask, selectedCountryDDICompany.ddi = itemListCompany.ddi, selectedCountryDDICompany.flag = itemListCompany.flag, selectedCountryCompany.name = itemListCompany.name, dropListCountry(false,'company'), errorForm.companyProfile.countryId = false">
                                                    <img :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${itemListCompany.flag}/flags`">
                                                    <a>{{itemListCompany.name}}</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <!-- PHONE COMPANY -->
                                    <div class="col-12 col-lg-6">
                                        <!-- <div class="form-ddi-phone" :class='{opened: dropListOpened.ddiComapny, active: selectedCountryDDICompany.active, error: (errorsPost.dataProfile === "account_activate_phone_already_exists" || errorForm.sellerProfile.phoneNumber)}'> -->
                                        <div class="form-ddi-phone" :class='{error: (errorsPost.dataProfile === "account_activate_phone_already_exists" || errorForm.companyProfile.phoneNumber)}'>
                                            <label>
                                                <span>{{getMyLanguage("purchase-token","purchase-token.content.box-register.placeholder.companyPhone")}}</span>
                                                <div class="select-ddi" :class='{disabled: selectedCountryDDICompany.phoneMask == "?"}' v-if="selectedCountryDDICompany.changeMask">
                                                    <div class="selected-ddi">
                                                        <div class="flag">
                                                            <img :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${selectedCountryDDICompany.flag}/flags`" v-if="selectedCountryDDICompany.flag != ''">
                                                        </div>
                                                        <span class="ddi" v-if="selectedCountryDDICompany.phoneMask != '?'">+{{selectedCountryDDICompany.ddi}}</span>
                                                    </div>
                                                    
                                                    <input v-if="selectedCountryDDICompany.phoneMask != '?'" v-model="validatePhone.company.val" id="phoneNumberCompany" type="text" class="form" v-mask="selectedCountryDDICompany.phoneMask" @keydown="errorForm.companyProfile.phoneNumber = false" @blur='regexPhone("company")' maxlength="25" autocomplete="off" >
                                                </div>
                                                <div class="form load-phone" v-if="!selectedCountryDDICompany.changeMask"></div>

                                                <p class="msg-validate-form msg-error regex-error anime" v-if='validatePhone.company.regex === false || errorForm.companyProfile.phoneNumber'>{{getMyLanguage("errors","errors.phoneError")}}</p>
                                                <p class="msg-validate-form msg-error anime" v-if='errorsPost.dataProfile === "account_activate_phone_already_exists"'>{{getMyLanguage("errors","errors.phoneRegistered")}}</p>
                                            </label>
                                        </div>
                                    </div>

                                    <div class="col-12">
                                        <label>
                                            <span>Logradouro</span>
                                            <input type="text" class="form">
                                        </label>
                                    </div>
                                    
                                    <div class="col-12 col-lg-6">
                                        <label>
                                            <span>Complemento</span>
                                            <input type="text" class="form">
                                        </label>
                                    </div>

                                    <div class="col-12 col-lg-6">
                                        <label>
                                            <span>Caixa Postal</span>
                                            <input type="text" class="form">
                                        </label>
                                    </div>
                                    
                                    <div class="col-12 col-lg-6">
                                        <label>
                                            <span>Cidade</span>
                                            <input type="text" class="form">
                                        </label>
                                    </div>
                                    
                                    <div class="col-12 col-lg-6">
                                        <label>
                                            <span>Estado</span>
                                            <input type="text" class="form">
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div class="action single">
                                <div class="btn-primary" @click='validateForm("stp01")'>Salvar e continuar</div>
                            </div>
                        </div>
                        

                        <!-- STEP 02 -->
                        <div class="cont-step-02" v-if='flowStep.stp == "step02" && !flowStep.load'>

                            <h2 class="title-secondary">Informações do vendedor</h2>

                            <div class="box-registration">
                                <div class="row formBox">

                                    <!-- FULLNAME SELLER -->
                                    <div class="col-12">
                                        <label class="formFullName">
                                            <span>{{getMyLanguage("purchase-token", "purchase-token.content.box-register.placeholder.fullname")}}</span>
                                            <input v-model="dataProfile.fullName" type="text" class="form" :class="{error: errorForm.sellerProfile.fullName}" @keydown="errorForm.sellerProfile.fullName = false" maxlength="80" autocomplete="off" >
                                            <p class="msg-validate-form msg-error anime" v-if='errorForm.sellerProfile.fullName'>{{getMyLanguage("errors","errors.nameError")}}</p>
                                        </label>
                                    </div>

                                    <div class="col-12 col-lg-6">
                                        <label>
                                            <span>Nacionalidade</span>
                                            <input type="text" class="form">
                                        </label>
                                    </div>

                                    <div class="col-12 col-lg-6">
                                        <label>
                                            <span>Posição na empresa</span>
                                            <select class="form">
                                                <option value="">Sócio</option>
                                                <option value="">Gerente</option>
                                                <option value="">Colaborador</option>
                                            </select>
                                        </label>
                                    </div>
                                    
                                    <div class="col-12 col-sm-6 col-lg-4">
                                        <label>
                                            <span>{{ getMyLanguage("client-space", "client-space.account.lbl.birthdate") }}</span>
                                            <input type="text" class="form">
                                        </label>
                                    </div>

                                    <div class="col-12 col-sm-6 col-lg-4">
                                        <label>
                                            <span>{{ getMyLanguage("client-space", "client-space.account.lbl.gender") }}</span>
                                            <select class="form">
                                                <option value="1">Feminino</option>
                                                <option value="2">Masculino</option>
                                            </select>
                                        </label>
                                    </div>

                                    <div class="col-12 col-sm-6 col-lg-4">
                                        <label>
                                            <span>{{ getMyLanguage("client-space", "client-space.account.lbl.taxNumber") }}</span>
                                            <input type="text" class="form">
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div class="box-registration">
                                <div class="row formBox">
                                    <!-- EMAIL SELLER -->
                                    <div class="col-12 col-lg-6">
                                        <div class="form-validate">
                                            <label class="formYourEmail">
                                                <span>{{getMyLanguage("purchase-token","purchase-token.content.box-register.placeholder.companyEmail")}}</span>
                                                <div class="email Pending">email@email.com</div>
                                                <small>* O registo está incompleto</small>
                                            </label>
                                        </div>
                                    </div>

                                    <!-- PHONE SELLER -->
                                    <div class="col-12 col-lg-6">
                                        <div class="form-validate">
                                            <label>
                                                <span>{{getMyLanguage("purchase-token", "purchase-token.content.box-register.placeholder.phone")}}</span>
                                                <!-- <img :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${selectedLanguageCompanyFlag}/flags`" class="flag-selected" v-if="selectedLanguageCompanyFlag != false"> -->
                                                <img src="https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/ef8d7ffc-7765-4aa5-75be-3c8758b7ff00/flags" class="flag">
                                                <div class="phone Active">351315135151</div>
                                            </label>

                                            <!-- <div class="validate-phone">Validar</div> -->
                                        </div>
                                    </div>

                                    <!-- CURRENCY SELLER -->
                                    <div class="col-12 col-lg-6">
                                        <label>
                                            <span>{{ getMyLanguage("client-space", "client-space.account.lbl.currency") }}</span>
                                            <select class="form">
                                                <option value="">Euro</option>
                                                <option value="">Dollar</option>
                                                <option value="">Real Brasileiro</option>
                                            </select>
                                        </label>
                                    </div>

                                    <!-- LANGUAGE SELLER -->
                                    <div class="col-12 col-lg-6">
                                        <div class="autocomplete has-label no-icon formLanguage" :class="{opened: dropListOpened.languageSeller}">
                                            <div class="openAutocomplete" v-if="!listLanguagesSellerStatus" @click="dropListLanguages(true,'seller')"></div>
                                            <div class="closeAutocomplete closeDropListCountry" v-if="listLanguagesSellerStatus" @click="dropListLanguages(false,'seller')"></div>

                                            <label>
                                                <span>{{getMyLanguage("purchase-token", "purchase-token.content.box-register.placeholder.language")}}</span>
                                                <img :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${selectedLanguageSellerFlag}/flags`" class="flag-selected" v-if="selectedLanguageSellerFlag != false">
                                                <input type="text" id="languageIDseller" class="form" :class="{selected: selectedLanguageSellerFlag != false, error: errorForm.sellerProfile.languageId}" @focus="dropListLanguages(true,'seller')" @keyup="filterListLanguage('seller')" @blur="dropListLanguages(false,'seller')" @keydown.esc="dropListLanguages(false,'seller')" :value="selectedLanguageSeller.name" :title='getMyLanguage("purchase-token", "purchase-token.content.box-register.placeholder.language")' autocomplete="off">
                                                <p class="msg-validate-form msg-error anime" v-if='errorForm.sellerProfile.languageId'>{{getMyLanguage("errors","errors.language")}}</p>
                                            </label>

                                            <ul id="drop-list-seller" v-if="listLanguagesSellerStatus">
                                                <li v-for="(itemList,index) in dataLanguage" :key="index" @click="dataProfile.sellerProfile.languageId = itemList.id, selectedLanguageSeller.name = itemList.name, selectedLanguageSellerFlag = itemList.flag, dropListLanguages(false,'seller'), errorForm.sellerProfile.languageId = false">
                                                    <img :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${itemList.flag}/flags`">
                                                    <a>{{itemList.name}}</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="box-registration">
                                <div class="row formBox">

                                    <!-- COUNTRY SELLER -->
                                    <div class="col-12 col-lg-6">
                                        <div class="autocomplete has-label no-icon formResidenceCountry" :class="{opened: dropListOpened.countrySeller}">
                                            <div class="openAutocomplete" v-if="!listCountrySellerStatus" @click="dropListCountry(true,'seller')"></div>
                                            <div class="closeAutocomplete closeDropListCountry" v-if="listCountrySellerStatus" @click="dropListCountry(false,'seller')"></div>

                                            <label>
                                                <img :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${selectedCountryFlagSeller}/flags`" class="flag-selected" v-if="selectedCountryFlagSeller != false">
                                                <span>{{getMyLanguage("purchase-token", "purchase-token.content.box-register.placeholder.residence")}}</span>
                                                <input type="text" id="residenceCountrySeller" class="form" :class="{selected: selectedCountryFlagSeller != false, error: errorForm.sellerProfile.countryId}" @focus="dropListCountry(true,'seller')" @blur="dropListCountry(false,'seller')" @keydown.esc="dropListCountry(false,'seller')" @keyup="filterListCountrySeller" :value="selectedCountrySeller.name" autocomplete="off">
                                                <p class="msg-validate-form msg-error anime" v-if='errorForm.sellerProfile.countryId'>{{getMyLanguage("errors","errors.residenceError")}}</p>
                                            </label>

                                            <ul id="drop-list" v-if="listCountrySellerStatus">
                                                <li v-for="(itemListSeller,index) in dataCountry" :key="index" @click="dataProfile.sellerProfile.countryId = itemListSeller.id, dataProfile.sellerProfile.phoneCountryId = itemListSeller.id, changeMask('seller'), selectedCountryFlagSeller = itemListSeller.flag, selectedCountryDDISeller.countrySelected = true, selectedCountryDDISeller.active = 'active', selectedCountryDDISeller.phoneMask = itemListSeller.phoneMask, selectedCountryDDISeller.ddi = itemListSeller.ddi, selectedCountryDDISeller.flag = itemListSeller.flag, selectedCountrySeller.name = itemListSeller.name, dropListCountry(false,'seller'), errorForm.sellerProfile.countryId = false">
                                                    <img :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${itemListSeller.flag}/flags`">
                                                    <a>{{itemListSeller.name}}</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <!-- PHONE SELLER -->
                                    <div class="col-12 col-lg-6">
                                        <div class="form-ddi-phone" :class='{error: (errorsPost.dataProfile === "account_activate_phone_already_exists" || errorForm.sellerProfile.phoneNumber)}'>
                                            <label>
                                                <span>{{getMyLanguage("purchase-token","purchase-token.content.box-register.placeholder.phone")}}</span>
                                                <div class="select-ddi" :class='{disabled: selectedCountryDDISeller.phoneMask == "?"}' v-if="selectedCountryDDISeller.changeMask">
                                                    <div class="selected-ddi">
                                                        <div class="flag">
                                                            <img :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${selectedCountryDDISeller.flag}/flags`" v-if="selectedCountryDDISeller.flag != ''">
                                                        </div>
                                                        <span class="ddi" v-if="selectedCountryDDISeller.phoneMask != '?'">+{{selectedCountryDDISeller.ddi}}</span>
                                                    </div>
                                                    
                                                    <input v-if="selectedCountryDDISeller.phoneMask != '?'" v-model="validatePhone.seller.val" id="phoneNumberSeller" type="text" class="form" v-mask="selectedCountryDDISeller.phoneMask" @keydown="errorForm.sellerProfile.phoneNumber = false" @blur='regexPhone("seller")' maxlength="25" autocomplete="off" >
                                                </div>
                                                <div class="form load-phone" v-if="!selectedCountryDDISeller.changeMask"></div>

                                                <p class="msg-validate-form msg-error regex-error anime" v-if='validatePhone.seller.regex === false || errorForm.sellerProfile.phoneNumber'>{{getMyLanguage("errors","errors.phoneError")}}</p>
                                                <p class="msg-validate-form msg-error anime" v-if='errorsPost.dataProfile === "account_activate_phone_already_exists"'>{{getMyLanguage("errors","errors.phoneRegistered")}}</p>
                                            </label>
                                        </div>
                                    </div>
                                    
                                    <div class="col-12">
                                        <label>
                                            <span>Logradouro</span>
                                            <input type="text" class="form">
                                        </label>
                                    </div>
                                    
                                    <div class="col-12 col-lg-6">
                                        <label>
                                            <span>Complemento</span>
                                            <input type="text" class="form">
                                        </label>
                                    </div>

                                    <div class="col-12 col-lg-6">
                                        <label>
                                            <span>Caixa Postal</span>
                                            <input type="text" class="form">
                                        </label>
                                    </div>
                                    
                                    <div class="col-12 col-lg-6">
                                        <label>
                                            <span>Cidade</span>
                                            <input type="text" class="form">
                                        </label>
                                    </div>
                                    
                                    <div class="col-12 col-lg-6">
                                        <label>
                                            <span>Estado</span>
                                            <input type="text" class="form">
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div class="action">
                                <div class="btn-back" @click='navStep("stp01")'><span>Voltar</span></div>
                                <div class="btn-primary" @click='validateForm("stp02")'>Salvar e continuar</div>
                            </div>
                        </div>


                        <!-- STEP 03 -->
                        <div class="cont-step-03" v-if='flowStep.stp == "step03" && !flowStep.load'>

                            <h2 class="title-secondary">Sua loja</h2>

                            <div class="box-registration">
                                <h3 class="subtitle">Sua loja na DNE Market</h3>
                                <div class="row formBox">

                                    <!-- STORE NAME -->
                                    <div class="col-12 col-lg-6">
                                        <label>
                                            <span>
                                                Nome da sua loja
                                                <small>* Este será o nome da sua loja na DNE Market</small>
                                            </span>
                                            <input type="text" class="form">
                                        </label>
                                    </div>

                                    <!-- LOGO -->
                                    <div class="col-12 col-lg-6">
                                        <label for="logoStore" class="upload">
                                            <span class="upload">Envie a logomarca <small>(svg, png ou jpg)</small></span>
                                            <em>Selecionar imagem</em>
                                            <!-- <em>Imagem selecionada</em> -->
                                            <input type="file" id="logoStore">
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div class="box-registration">
                                <h3 class="subtitle">Topo da sua loja</h3>

                                <div class="row formBox">
                                    <div class="col-12 col-lg-6">
                                        <label>
                                            <span>
                                                Imagem de topo para sua loja
                                                <small>Escolha uma opção ou envie sua própria imagem</small>
                                            </span>
                                            <div class="btn-open" @click="flowHeaderStore.modal = true">Escolher imagem</div>
                                        </label>
                                    </div>

                                    <div class="col-12">

                                        <div class="header-store" style="background: url('https://cdn.dnemarket.com/topo-tilt.jpg')">
                                            <img src="https://cdn.dnemarket.com/tilt.png" alt="Tilt Informatica" class="store-logo">
                                            <!-- <span class="store-logo">TI</span> -->
                                            <h2 class="store-name">Tilt Informática</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- STORE CONTACT -->
                            <div class="box-registration">
                                <div class="row formBox" v-if="!flowContact.newContact">
                                    <div class="col-12">
                                        <div class="check-show-contacts" :class="{active: flowContact.showContacts}">
                                            <h3 class="subtitle">Deseja exibir seus contactos aos clientes?</h3>

                                            <div class="form-group-radio">
                                                <input type="radio" v-model="flowContact.showContacts" id="showContactsTrue" :value="true" name="radioPEP" class="form-radio" @click='flowContact.showContacts = true' :checked='flowContact.showContacts' >
                                                <label for="showContactsTrue" class="form-radio-label">
                                                    <span>{{getMyLanguage("client-space","client-space.account.modal.btn-yes")}}</span>
                                                </label>

                                                <input type="radio" v-model="flowContact.showContacts" id="showContactsFalse" :value="false" name="radioPEP" class="form-radio" @click="flowContact.showContacts = false" :checked='!flowContact.showContacts' >
                                                <label for="showContactsFalse" class="form-radio-label">
                                                    <span>{{getMyLanguage("client-space","client-space.account.modal.btn-no")}}</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row formBox" v-if="flowContact.showContacts && !flowContact.newContact">
                                    <div class="col-12">
                                        <div class="adresses adresses-contact">
                                            <div class="address selected" @click="changeContact('data do address')">
                                                <span>Endereço 01 (dados da etapa 01)</span>
                                            </div>

                                            <div class="address add" @click="changeContact('new')">
                                                <span>Adicionar outro endereço</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row formBox show" v-if="flowContact.showContacts && flowContact.newContact">
                                    <div class="col-12">
                                        <h3 class="subtitle">Preencha as informações de contacto</h3>
                                    </div>

                                    <!-- COUNTRY CONTACT -->
                                    <div class="col-12 col-lg-6">
                                        <div class="autocomplete has-label no-icon formResidenceCountry" :class="{opened: dropListOpened.countryContact}">
                                            <div class="openAutocomplete" v-if="!listCountryContactStatus" @click="dropListCountry(true,'contact')"></div>
                                            <div class="closeAutocomplete closeDropListCountry" v-if="listCountryContactStatus" @click="dropListCountry(false,'contact')"></div>

                                            <label>
                                                <img :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${selectedCountryFlagContact}/flags`" class="flag-selected" v-if="selectedCountryFlagContact != false">
                                                <span>{{getMyLanguage("client-space", "client-space.account.lbl.country")}}</span>
                                                <input type="text" id="residenceCountryContact" class="form" :class="{selected: selectedCountryFlagContact != false, error: errorForm.contactProfile01.countryId}" @focus="dropListCountry(true,'contact')" @blur="dropListCountry(false,'contact')" @keydown.esc="dropListCountry(false,'contact')" @keyup="filterListCountryContact" :value="selectedCountryContact.name" autocomplete="off">
                                                <p class="msg-validate-form msg-error anime" v-if='errorForm.contactProfile01.countryId'>{{getMyLanguage("errors","errors.residenceError")}}</p>
                                            </label>

                                            <ul id="drop-list" v-if="listCountryContactStatus">
                                                <li v-for="(itemListContact,index) in dataCountry" :key="index" @click="dataProfile.contactProfile.countryId = itemListContact.id, dataProfile.contactProfile.phoneCountryId = itemListContact.id, changeMask('contact'), selectedCountryFlagContact = itemListContact.flag, selectedCountryDDIContact01.countrySelected = true, selectedCountryDDIContact01.active = 'active', selectedCountryDDIContact01.phoneMask = itemListContact.phoneMask, selectedCountryDDIContact02.phoneMask = itemListContact.phoneMask, selectedCountryDDIContact01.ddi = itemListContact.ddi, selectedCountryDDIContact02.ddi = itemListContact.ddi, selectedCountryDDIContact01.flag = itemListContact.flag, selectedCountryDDIContact02.flag = itemListContact.flag, selectedCountryContact.name = itemListContact.name, dropListCountry(false,'contact'), errorForm.contactProfile01.countryId = false, errorForm.contactProfile02.countryId = false">
                                                    <img :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${itemListContact.flag}/flags`">
                                                    <a>{{itemListContact.name}}</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 d-none d-lg-block"></div>

                                    <!-- PHONE CONTACT 01 -->
                                    <div class="col-12 col-lg-6">
                                        <div class="form-ddi-phone" :class='{error: (errorsPost.dataProfile === "account_activate_phone_already_exists" || errorForm.contactProfile01.phoneNumber)}'>
                                            <label>
                                                <span>Telefone principal</span>
                                                <div class="select-ddi" :class='{disabled: selectedCountryDDIContact01.phoneMask == "?"}' v-if="selectedCountryDDIContact01.changeMask">
                                                    <div class="selected-ddi">
                                                        <div class="flag">
                                                            <img :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${selectedCountryDDIContact01.flag}/flags`" v-if="selectedCountryDDIContact01.flag != ''">
                                                        </div>
                                                        <span class="ddi" v-if="selectedCountryDDIContact01.phoneMask != '?'">+{{selectedCountryDDIContact01.ddi}}</span>
                                                    </div>
                                                    
                                                    <input v-if="selectedCountryDDIContact01.phoneMask != '?'" v-model="validatePhone.contact01.val" id="phoneNumberContact01" type="text" class="form" v-mask="selectedCountryDDIContact01.phoneMask" @keydown="errorForm.contactProfile01.phoneNumber = false" @blur='regexPhone("contact01")' maxlength="25" autocomplete="off" >
                                                </div>
                                                <div class="form load-phone" v-if="!selectedCountryDDIContact01.changeMask"></div>

                                                <p class="msg-validate-form msg-error regex-error anime" v-if='validatePhone.contact01.regex === false || errorForm.contactProfile01.phoneNumber'>{{getMyLanguage("errors","errors.phoneError")}}</p>
                                                <p class="msg-validate-form msg-error anime" v-if='errorsPost.dataProfile === "account_activate_phone_already_exists"'>{{getMyLanguage("errors","errors.phoneRegistered")}}</p>
                                            </label>
                                        </div>
                                    </div>


                                    <!-- PHONE CONTACT 02 -->
                                    <div class="col-12 col-lg-6">
                                        <div class="form-ddi-phone" :class='{error: (errorsPost.dataProfile === "account_activate_phone_already_exists" || errorForm.contactProfile02.phoneNumber)}'>
                                            <label>
                                                <span>Telefone secundário</span>
                                                <div class="select-ddi" :class='{disabled: selectedCountryDDIContact02.phoneMask == "?"}' v-if="selectedCountryDDIContact02.changeMask">
                                                    <div class="selected-ddi">
                                                        <div class="flag">
                                                            <img :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${selectedCountryDDIContact02.flag}/flags`" v-if="selectedCountryDDIContact02.flag != ''">
                                                        </div>
                                                        <span class="ddi" v-if="selectedCountryDDIContact02.phoneMask != '?'">+{{selectedCountryDDIContact02.ddi}}</span>
                                                    </div>
                                                    
                                                    <input v-if="selectedCountryDDIContact02.phoneMask != '?'" v-model="validatePhone.contact02.val" id="phoneNumberContact02" type="text" class="form" v-mask="selectedCountryDDIContact02.phoneMask" @keydown="errorForm.contactProfile02.phoneNumber = false" @blur='regexPhone("contact02")' maxlength="25" autocomplete="off" >
                                                </div>
                                                <div class="form load-phone" v-if="!selectedCountryDDIContact02.changeMask"></div>

                                                <p class="msg-validate-form msg-error regex-error anime" v-if='validatePhone.contact02.regex === false || errorForm.contactProfile02.phoneNumber'>{{getMyLanguage("errors","errors.phoneError")}}</p>
                                                <p class="msg-validate-form msg-error anime" v-if='errorsPost.dataProfile === "account_activate_phone_already_exists"'>{{getMyLanguage("errors","errors.phoneRegistered")}}</p>
                                            </label>
                                        </div>
                                    </div>
                                    
                                    <div class="col-12">
                                        <label>
                                            <span>Logradouro</span>
                                            <input type="text" class="form">
                                        </label>
                                    </div>
                                    
                                    <div class="col-12 col-lg-6">
                                        <label>
                                            <span>Complemento</span>
                                            <input type="text" class="form">
                                        </label>
                                    </div>

                                    <div class="col-12 col-lg-6">
                                        <label>
                                            <span>Caixa Postal</span>
                                            <input type="text" class="form">
                                        </label>
                                    </div>
                                    
                                    <div class="col-12 col-lg-6">
                                        <label>
                                            <span>Cidade</span>
                                            <input type="text" class="form">
                                        </label>
                                    </div>
                                    
                                    <div class="col-12 col-lg-6">
                                        <label>
                                            <span>Estado</span>
                                            <input type="text" class="form">
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <!-- SELECT THEME -->
                            <div class="box-registration box-theme">
                                <div class="row formBox">
                                    <div class="col-12">
                                        <h3 class="subtitle">Selecione um tema para sua loja</h3>

                                        <div class="templates">
                                            <div class="cont">
                                                <div class="template active" @click="previewTheme()">
                                                    <div class="image">
                                                        <img src="~@/assets/images/content/template-dne-market-01.jpg">
                                                    </div>
                                                    <h4>Nome do tema</h4>
                                                </div>

                                                <div class="template" @click="previewTheme()">
                                                    <div class="image">
                                                        <img src="~@/assets/images/content/template-dne-market-02.jpg">
                                                    </div>
                                                    <h4>Nome do tema</h4>
                                                </div>

                                                <div class="template" @click="previewTheme()">
                                                    <div class="image">
                                                        <img src="~@/assets/images/content/template-dne-market-03.jpg">
                                                    </div>
                                                    <h4>Nome do tema</h4>
                                                </div>

                                                <div class="template" @click="previewTheme()">
                                                    <div class="image">
                                                        <img src="~@/assets/images/content/template-dne-market-01.jpg">
                                                    </div>
                                                    <h4>Nome do tema</h4>
                                                </div>

                                                <div class="template" @click="previewTheme()">
                                                    <div class="image">
                                                        <img src="~@/assets/images/content/template-dne-market-02.jpg">
                                                    </div>
                                                    <h4>Nome do tema</h4>
                                                </div>

                                                <div class="template" @click="previewTheme()">
                                                    <div class="image">
                                                        <img src="~@/assets/images/content/template-dne-market-03.jpg">
                                                    </div>
                                                    <h4>Nome do tema</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <!-- STORE DOCK -->
                            <div class="box-registration box-docks">
                                <div class="row formBox">
                                    <div class="col-12">
                                        <!-- <div class="check-show-contacts" :class="{active: dataProfile.showContacts}"> -->
                                        <div class="check-show-contacts">
                                            <h3 class="subtitle">Endereço do armazém (origem de entrega)</h3>

                                            <div class="adresses" v-if="!flowDock.newDock">

                                                <!-- ao salvar o step 01, chamar a função changeDock() aplicando o endereço deste step -->
                                                <div class="address selected" @click="changeDock()">
                                                    <span>Endereço 01 (dados da etapa 01)</span>
                                                </div>
                                                
                                                <div class="address" @click="changeDock()">
                                                    <span>Endereço 02 (dados de contacto aos clientes)</span>
                                                </div>

                                                <div class="address add" @click="changeDock('new')">
                                                    <span>Adicionar outro endereço</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row formBox show" v-if="flowDock.newDock">

                                    <!-- COUNTRY DOCK -->
                                    <div class="col-12 col-lg-6">
                                        <div class="autocomplete has-label no-icon formResidenceCountry" :class="{opened: dropListOpened.countryDock}">
                                            <div class="openAutocomplete" v-if="!listCountryDockStatus" @click="dropListCountry(true,'dock')"></div>
                                            <div class="closeAutocomplete closeDropListCountry" v-if="listCountryDockStatus" @click="dropListCountry(false,'dock')"></div>

                                            <label>
                                                <img :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${selectedCountryFlagDock}/flags`" class="flag-selected" v-if="selectedCountryFlagDock != false">
                                                <span>País do armazém</span>
                                                <input type="text" id="residenceCountryDock" class="form" :class="{selected: selectedCountryFlagDock != false, error: errorForm.dockProfile.countryId}" @focus="dropListCountry(true,'dock')" @blur="dropListCountry(false,'dock')" @keydown.esc="dropListCountry(false,'dock')" @keyup="filterListCountryDock" :value="selectedCountryDock.name" autocomplete="off">
                                                <p class="msg-validate-form msg-error anime" v-if='errorForm.dockProfile.countryId'>{{getMyLanguage("errors","errors.residenceError")}}</p>
                                            </label>

                                            <ul id="drop-list" v-if="listCountryDockStatus">
                                                <li v-for="(itemListDock,index) in dataCountry" :key="index" @click="dataProfile.dockProfile.countryId = itemListDock.id, dataProfile.dockProfile.phoneCountryId = itemListDock.id, changeMask('dock'), selectedCountryFlagDock = itemListDock.flag, selectedCountryDDIDock.countrySelected = true, selectedCountryDDIDock.active = 'active', selectedCountryDDIDock.phoneMask = itemListDock.phoneMask, selectedCountryDDIDock.ddi = itemListDock.ddi, selectedCountryDDIDock.flag = itemListDock.flag, selectedCountryDock.name = itemListDock.name, dropListCountry(false,'dock'), errorForm.dockProfile.countryId = false">
                                                    <img :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${itemListDock.flag}/flags`">
                                                    <a>{{itemListDock.name}}</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <!-- PHONE DOCK -->
                                    <div class="col-12 col-lg-6">
                                        <div class="form-ddi-phone" :class='{error: (errorsPost.dataProfile === "account_activate_phone_already_exists" || errorForm.dockProfile.phoneNumber)}'>
                                            <label>
                                                <span>Telefone do armazém</span>
                                                <div class="select-ddi" :class='{disabled: selectedCountryDDIDock.phoneMask == "?"}' v-if="selectedCountryDDIDock.changeMask">
                                                    <div class="selected-ddi">
                                                        <div class="flag">
                                                            <img :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${selectedCountryDDIDock.flag}/flags`" v-if="selectedCountryDDIDock.flag != ''">
                                                        </div>
                                                        <span class="ddi" v-if="selectedCountryDDIDock.phoneMask != '?'">+{{selectedCountryDDIDock.ddi}}</span>
                                                    </div>
                                                    
                                                    <input v-if="selectedCountryDDIDock.phoneMask != '?'" v-model="validatePhone.dock.val" id="phoneNumberDock" type="text" class="form" v-mask="selectedCountryDDIDock.phoneMask" @keydown="errorForm.dockProfile.phoneNumber = false" @blur='regexPhone("dock")' maxlength="25" autocomplete="off" >
                                                </div>
                                                <div class="form load-phone" v-if="!selectedCountryDDIDock.changeMask"></div>

                                                <p class="msg-validate-form msg-error regex-error anime" v-if='validatePhone.dock.regex === false || errorForm.dockProfile.phoneNumber'>{{getMyLanguage("errors","errors.phoneError")}}</p>
                                                <p class="msg-validate-form msg-error anime" v-if='errorsPost.dataProfile === "account_activate_phone_already_exists"'>{{getMyLanguage("errors","errors.phoneRegistered")}}</p>
                                            </label>
                                        </div>
                                    </div>
                                    
                                    <div class="col-12">
                                        <label>
                                            <span>Logradouro</span>
                                            <input type="text" class="form">
                                        </label>
                                    </div>
                                    
                                    <div class="col-12 col-lg-6">
                                        <label>
                                            <span>Complemento</span>
                                            <input type="text" class="form">
                                        </label>
                                    </div>

                                    <div class="col-12 col-lg-6">
                                        <label>
                                            <span>Caixa Postal</span>
                                            <input type="text" class="form">
                                        </label>
                                    </div>
                                    
                                    <div class="col-12 col-lg-6">
                                        <label>
                                            <span>Cidade</span>
                                            <input type="text" class="form">
                                        </label>
                                    </div>
                                    
                                    <div class="col-12 col-lg-6">
                                        <label>
                                            <span>Estado</span>
                                            <input type="text" class="form">
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <Footer />
        <CookieConsent />


        <!-- MODAL PHONE VALIDATE -->
        <div class="modal" v-if="modalPhoneValidate">
            <div class="modal-cont">
                <div class="modal-close" @click="modalPhoneValidate = false"></div>
                
                <h2 class="title-secondary">{{getMyLanguage("profile","profile.account.lbl.phone.validate")}}</h2>
                <p class="step-validate" v-if="!flowSendSMS.success && !errorsFlowSendSMS.flow" v-html='getMyLanguage("profile","profile.account.lbl.phone.validate-step01")'></p>
                
                <div class="load" v-if="flowSendSMS.load"></div>

                <!-- SMS CODE -->
                <div class="sms-code" v-if="!flowSendSMS.success && !errorsFlowSendSMS.flow">                        
                    <div class="btn-primary getCode" v-if="!flowSendSMS.smsCodeShow && !flowSendSMS.load" @click="getCodeSend()">{{getMyLanguage("wallets","modal-send-code.btn-generate")}}</div>

                    <div class="sms-code-cont">
                        <div class="invalid-code" v-if="errorsFlowSendSMS.invalidCode">{{getMyLanguage("profile","profile.account.lbl.phone.validate-error")}}</div>

                        <div class="sms-confirm" :class="{errorCode: errorsFlowSendSMS.invalidCode}" v-if="flowSendSMS.smsCodeShow && !flowSendSMS.load">
                            <input type="text" class="form form-smsCode" id="smsCodeForm" v-model="codeSMS.code" v-mask="'000000'" placeholder="______" inputmode="numeric" autocomplete="one-time-code">
                            <div class="confirm btn-disabled" v-if="codeSMS.code.length < 6">{{getMyLanguage("wallets","modal-send-code.btn-confirm")}}</div>
                            <div class="confirm" v-if="codeSMS.code.length == 6" @click="validateCodeSMS()">{{getMyLanguage("wallets","modal-send-code.btn-confirm")}}</div>
                        </div>
                        
                        <div class="alert" v-if="flowSendSMS.smsCodeShow && !flowSendSMS.load">
                            {{getMyLanguage("wallets","modal-send-code.msg-sms")}} +{{$store.state.profile.profile_phone_country_ddi}} {{ maskedPhone($store.state.profile.profile_phone_number) }}
                        </div>
                        <!-- <div class="load" v-if="flowSendSMS.smsCodeShow && flowSendSMS.load"></div> -->
                    </div>


                    <div class="resend" v-if="flowSendSMS.smsCodeShow && !flowSendSMS.load">
                        <div class="btn-resend resend-load" v-if="!flowSendSMS.btnResendSMS">{{getMyLanguage("wallets","modal-send-code.btn-resend-in")}} {{flowSendSMS.countDownResendCode}}s</div>
                        <div class="btn-resend" v-if="flowSendSMS.btnResendSMS" @click="getCodeSend()">{{getMyLanguage("wallets","modal-send-code.btn-resend")}}</div>
                    </div>
                </div>


                <!-- BOX SUCCESS -->
                <div class="box-success" v-if="flowSendSMS.success">
                    <p v-html='getMyLanguage("profile","profile.account.lbl.phone.validate-ok")'></p>
                </div>


                <!-- BOX ERROR -->
                <div class="box-error" v-if="errorsFlowSendSMS.flow != false">
                    <h3 class="title-secondary">{{ getMyLanguage("box-error", "title-error") }}</h3>
                    <p>{{ getMyLanguage("box-error","msg-error") }}</p>
                    <div class="cod-error">
                        Cód.: {{errorsFlowSendSMS.flow}}
                    </div>
                </div>
            </div>
        </div>


        <!-- MODAL HEADER STORE -->
        <div class="modal" :class="{modalHeaderStore: !flowHeaderStore.showUpload}" v-if="flowHeaderStore.modal">
            <div class="modal-cont">
                <div class="modal-close" @click="flowHeaderStore.modal = false, flowHeaderStore.showUpload = false, flowHeaderStore.confirm = false" v-if="!flowHeaderStore.confirm"></div>
                
                <div class="modal-scroll" v-if="!flowHeaderStore.showUpload">
                    <h2 class="title-secondary">Topo da loja</h2>

                    <select class="form">
                        <option value="000">Filtar por categoria</option>
                        <option value="111">Tecnologia (computadores, periféricos ...)</option>
                        <option value="222">Games (consolas, jogos ...)</option>
                        <option value="333">Esportes</option>
                        <option value="444">Telemóveis (aparelhos, acessórios ...)</option>
                        <option value="555">Vestuário (roupas, calçados ...)</option>
                    </select>

                    <div class="headers">
                        <div class="header-store active" @click="flowHeaderStore.confirm = true" style="background: url('/headerstore/tecnologia-01.jpg')">
                            <img src="https://cdn.dnemarket.com/tilt.png" alt="Tilt Informatica" class="store-logo">
                            <h2 class="store-name">Tilt Informática</h2>
                        </div>
                        <p><span>Nome da opção <small>Tecnologia</small></span></p>

                        <div class="header-store" @click="flowHeaderStore.confirm = true" style="background: url('/headerstore/tecnologia-02.jpg')">
                            <span class="store-logo">TI</span>
                            <h2 class="store-name">Tilt Informática</h2>
                        </div>
                        <p><span>Nome da opção <small>Tecnologia</small></span></p>

                        <div class="header-store" @click="flowHeaderStore.confirm = true" style="background: url('/headerstore/tecnologia-03.jpg')">
                            <img src="https://cdn.dnemarket.com/tilt.png" alt="Tilt Informatica" class="store-logo">
                            <h2 class="store-name">Tilt Informática</h2>
                        </div>
                        <p><span>Nome da opção <small>Tecnologia</small></span></p>

                        <div class="header-store" @click="flowHeaderStore.confirm = true" style="background: url('/headerstore/tecnologia-04.jpg')">
                            <img src="https://cdn.dnemarket.com/tilt.png" alt="Tilt Informatica" class="store-logo">
                            <h2 class="store-name">Tilt Informática</h2>
                        </div>
                        <p><span>Nome da opção <small>Tecnologia</small></span></p>
                    </div>
                </div>


                <!-- UPLOAD -->
                <div class="open-upload" @click="flowHeaderStore.showUpload = true" v-if="!flowHeaderStore.showUpload">
                    <span>Add imagem para topo</span>
                </div>

                <div class="upload-header-store" v-if="flowHeaderStore.showUpload">
                    <h2 class="title-secondary">Upload</h2>

                    <label for="headerStore" class="upload">
                        <span class="upload">Imagem de topo para sua loja <small>(recomendado: 1920px x 500px)</small></span>
                        <em>Selecionar imagem</em>
                        <input type="file" id="headerStore">
                    </label>
                </div>


                <!-- CONFIRM -->
                <div class="confirm" v-if="flowHeaderStore.confirm">
                    <div class="load" v-if="flowHeaderStore.load"></div>

                    <div class="box" v-if="!flowHeaderStore.load">
                        <h3 class="title-secondary">Atenção</h3>
                        <p>Confirma a alteração do topo da loja?</p>
                        <div class="bts">
                            <div class="btn btn-no" @click="flowHeaderStore.confirm = false">NÃO</div>
                            <div class="btn btn-yes" @click="changeHeaderStore()">SIM</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <!-- MODAL CHANGE THEME -->
        <div class="modal modalThemeStore" v-if="flowThemeStore.modal">
            <div class="modal-cont">
                <div class="modal-close" @click="flowThemeStore.modal = false, flowThemeStore.confirm = false" v-if="!flowThemeStore.confirm"></div>
                
                <div class="modal-scroll">
                    <div class="header-theme">
                        <h3>Nome do tema</h3>

                        <div class="medias">
                            <span class="mobile" :class="{active: flowThemeStore.device == 'mobile'}" @click="flowThemeStore.device = 'mobile'">
                                <img src="~@/assets/images/icons/device-mobile.svg">
                            </span>

                            <span class="tablet" :class="{active: flowThemeStore.device == 'tablet'}" @click="flowThemeStore.device = 'tablet'">
                                <img src="~@/assets/images/icons/device-tablet.svg">
                            </span>

                            <span class="desktop" :class="{active: flowThemeStore.device == 'desktop'}" @click="flowThemeStore.device = 'desktop'">
                                <img src="~@/assets/images/icons/device-desktop.svg">
                            </span>
                        </div>
                    </div>
                    
                    <div class="theme">
                        <img src="/themes/theme-01-mobile.jpg" class="theme-mobile" v-if="flowThemeStore.device == 'mobile'">
                        <img src="/themes/theme-01-tablet.jpg" class="theme-tablet" v-if="flowThemeStore.device == 'tablet'">
                        <img src="/themes/theme-01-desktop.jpg" class="theme-desktop" v-if="flowThemeStore.device == 'desktop'">
                    </div>
                </div>
                
                <!-- CONFIRM -->
                <div class="open-confirm" @click="flowThemeStore.confirm = true" v-if="!flowThemeStore.confirm">
                    <span>Aplicar este tema</span>
                </div>

                <div class="confirm" v-if="flowThemeStore.confirm">
                    <div class="load" v-if="flowThemeStore.load"></div>

                    <div class="box" v-if="!flowThemeStore.load">
                        <h3 class="title-secondary">Atenção</h3>
                        <p>Confirma a alteração do tema?</p>
                        <div class="bts">
                            <div class="btn btn-no" @click="flowThemeStore.confirm = false">NÃO</div>
                            <div class="btn btn-yes" @click="changeThemeStore()">SIM</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Header from '@/components/Header.vue';
    import Footer from '@/components/Footer.vue';
    import CookieConsent from '@/components/CookieConsent.vue';
    
    import customScripts from "./script-profile-corporate.js";
  
    export default {
        components: {
            Header,
            Footer,
            CookieConsent
        },

        data(){
            return Object.assign({}, customScripts.data.call(this));
        },
        
        async mounted(){
            await customScripts.mounted.call(this);
        },

        methods: {
            ...customScripts.methods,
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        },

        created () {
            document.title = this.getMyLanguage("profile","profile-corporate.title");
            document.getElementsByTagName('meta')["description"].content = ''
            document.head.querySelector('meta[name=robots]').content = 'noindex, nofollow'
        },
    }
</script>

<style src="./custom-profile-corporate.scss" lang="scss" scoped />